<template>
  <div class="Facility">
    <ContainerCard :locationMsg="locationMsg">
      <!-- 搜索 -->
      <!-- <Search
        v-if="searchMsg.type.length > 0"
        :searchMsg="searchMsg"
        @searchKey="searchHandle"
      ></Search> -->
      <div class="search">
        <div class="title">
          <img src="../../assets/images/信息资讯.png" alt="" />
          <span class="title-size">设备库</span>
        </div>
        <div class="search-box">
          <span
            ><img
              src="../../assets/images/政策公告-搜索.png"
              class="search-icon"
          /></span>
          <input
            class="input"
            v-model="input"
            placeholder="请输入您要搜索的内容"
          />
          <div class="search-button" @click="searchHandle">
            <span class="button-word">搜索</span>
          </div>
        </div>
      </div>
      <Technology style="width: 1260px" @techHandle="techHandle"></Technology>
      <!-- 成果库列表 -->
      <div class="achievement-container-wrapper" v-empty="List">
        <div
          v-for="(item, index) in List"
          :class="[
            'achievement-container',
            index % 2 == 0
              ? 'achievement-container-left'
              : 'achievement-container-right',
          ]"
          :key="index"
          @click="toDetail(item.billid)"
        >
          <div class="achidevments">
              <!-- img -->
              <el-image
                class="img"
                :src="httpConfig.baseURL+item.device_photo"
                alt=""
                v-if="item.device_photo"
              />
              <div v-if="!item.device_photo" class="no-imgbox">
               <el-image class="no-img" :src="defaultImg" alt="" />
              </div>
              <!-- word/logo -->
              <div class="achidevments-info">
                <div class="text1">
                  <span class="name">{{ item.device_name }}</span>
                </div>
  
                  <div class="text2">
                    <span class="label">单位名称</span>
                    <span class="value">{{ item.company }}</span>
                  </div>
                  <div class="text2">
                    <span class="label">联系地址</span>
                    <span class="value">{{ item.address }}</span>
                  </div>
                
              </div>
       
            <!-- line -->
          </div>
        </div>
      </div>
      <!-- 分页器 -->
      <Pagination
        class="center"
        @changeIndex="setCurrentPage"
        :total="total"
        :totalPage="totalPage"
        :pageSize="params.pageSize"
      ></Pagination>
    </ContainerCard>
  </div>
</template>
<script>
import Location from "../../components/Location.vue";
import Search from "../../components/SixLibraries/Search.vue";
import Pagination from "../../components/Pagination.vue";
import { getEquipments } from "../../api/SixLibraries/Facility/Facility.js";
import ContainerCard from "../../components/newContainerCard.vue";
import Technology from "../../components/Info/Technology.vue";
import { mapGetters } from "vuex";
import defaultImg from "../../assets/images/暂无数据默认图片.png";
import httpConfig from "../../common/utils/config";
export default {
  computed: {
    ...mapGetters(["techfieldList"]),
  },

  data() {
    return {
      httpConfig,
      defaultImg,
      searchMsg: {
        title: "技术领域",
        type: [],
      },
      input: "",
      locationMsg: [],
      List: [],
      total: 0,
      totalPage: 0,
      fullscreenLoading: false,
      params: {
        tech_field: "", //技术领域
        keyword: "",
        orderstr: "make_date", //click_num
        pageNo: 1,
        pageSize: 6,
      },
    };
  },
  created() {
    let techList = [];
    techList.push({
      label: "全部",
      value: "all",
    });

    Object.keys(this.techfieldList).forEach((item) => {
      techList.push({
        label: item,
        value: this.techfieldList[item],
      });
    });
    this.searchMsg.type = techList;
  },
  methods: {
    async getData() {
      let that = this;
      that.fullscreenLoading = true;
      let res = await getEquipments(that.params);
      that.List = res.data.items.map((item) => {
        let tmp = item;
        tmp.techList = [];
        tmp.achievement_desc = item.achievement_desc;
        Object.keys(that.techfieldList).forEach(function (key) {
          if (
            tmp?.tech_field &&
            tmp?.tech_field.split(",").indexOf(that.techfieldList[key]) > -1
          ) {
            tmp.techList.push(key);
          }
        });
        return tmp;
      });
      this.total = res.data.totalCount;
      // this.totalPage = res.data.totalPage
      this.totalPage = Math.ceil(res.data.totalCount / this.params.pageSize);
      that.fullscreenLoading = false;
    },
    searchHandle(val) {
      this.params.keyword = this.input;
      // this.params.tech_field = val === "all" ? "" : val;
      this.getData();
    },
    techHandle(val) {
      this.params.keyword = this.input;
      this.params.tech_field = val === "all" ? "" : val;
      this.getData(); 
    },
    setCurrentPage(val) {
      this.params.pageNo = val;
      this.getData();
    },
    toDetail(id) {
      this.$router.push({
        path: "/Facility/FacilityDetails",
        query: { id: id },
      });
    },
  },
  mounted() {
    this.getData();
  },
  components: { Location, Search, Pagination, ContainerCard, Technology },
};
</script>
<style scoped lang="scss">
.center {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 118px;
}

// 列表容器样式
@mixin setHeightAndWidth($w, $h) {
  width: $w;
  height: $h;
}
@mixin setFont($size, $lih, $col) {
  font-size: $size;
  font-family: 思源黑体 CN Medium;
  line-height: $lih;
  color: $col;
}
.search {
  height: 86px;
  width: 1260px;
  box-sizing: border-box;
  padding: 24px 0px 24px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    img {
      @include setHeightAndWidth(8px, 10px);
      margin-right: 8px;
    }
    .title-size{
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #101010;
    }
  }
  .search-box {
    box-sizing: border-box;
    width: 696px;
    height: 38px;
    padding: 12px 0px 12px 12px;
    display: flex;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #2885ff;
    align-items: center;
    margin-bottom: 12px;
    .input {
      height: 34px;
      width: 572px;
      flex: 1;
      border: none;
      outline: none;
      font-size: 18px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #999999;
      width: 100%;
      &::-moz-placeholder {
        font-size: 14px;
        color: #999999;
      }
      &::-webkit-input-placeholder {
        font-size: 14px;
        color: #999999;
      }
    }
    > span {
      height: 100%;
      display: flex;
      align-items: center;
      &:active {
        transform: scale(0.96);
      }
    }
    > span .search-icon {
      height: 12px;
      width: 12px;
      margin-right: 8px;
    }
    .search-button {
      cursor: pointer;
      box-sizing: border-box;
      padding: 1px;
      border-radius: 0px 6px 6px 0px;
      width: 72px;
      height: 38px;
      background-color: #2885ff;
      color: #ffffff;
      text-align: center;
      line-height: 38px;
      span {
        font-size: 16px;
      }
    }
  }
}

.achievement-container-wrapper {
  // width: 100%;
  // height: 100%;
  width: 1260px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr; /// 三列三等分
  grid-gap: 0 0; // 行间距，列间距

  .achievement-container-left {
    border-right: 1px solid #f1f1f1;
    padding-left: 0px;
    &:hover {
      padding-left: 30px;
    }
  }
  .achievement-container-right {
    padding-left: 30px !important;
  }
  .achievement-container {
    // width: 100%;
    // height: 100%;
    width: 630px;
    box-sizing: border-box;
    padding: 20px 16px 16px 16px;
    border-bottom: 1px solid #f1f1f1;
    cursor: pointer;
    // &:nth-child(odd) {
    //   border-right: 0px solid #f1f1f1;
    // }
    // &:nth-child(even) {
    //   border-left: 0px solid #f1f1f1;
    //   padding-left: 0px;
    //   &:hover {
    //     padding-left: 30px;
    //   }
    // }
    &:hover {
      box-shadow: 0px 12px 40px rgba($color: #000000, $alpha: 0.12);
      z-index: 999;
    }
    cursor: pointer;
    .achidevments {
      // width: 100%;
      // height: 100%;
      // display: flex;
      // flex-direction: column;
      // justify-content: space-between;
      // align-items: center;
      // position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      .img {
        width: 150px;
        height: 90px;
        margin-right: 10px;
        border-radius: 6px;
      }
      .no-imgbox {
        width: 150px;
        height: 90px;
        background: #F1F1F1;
        border-radius: 6px;
        text-align: center;
        line-height: 90px;
        .no-img {
        width: 24px;
        height: 24px;
      }
      }
      .achidevments-info {
        height: 92px;
        // width: 100%;
        box-sizing: border-box;
        padding: 9px 20px 9px 20px;
        .text1 {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          .name {
            @include setFont(18px, 27px, #101010);
            font-weight: 600;
            margin-right: 12px;
          }

          .techList {
            @include setFont(12px, 18px, #ffffff);
            padding: 3px 5px 0px 5px;
            box-sizing: border-box;
            background-color: #2885ff;
            border-radius: 3px;
          }
        }
        .text2 {
          .label {
            @include setFont(12px, 18px, #8896a7);
            margin-right: 20px;
            margin-bottom: 12px;
          }
          .value {
            @include setFont(12px, 18px, #404040);
          }

          // &:nth-child(1) {
          //   margin-bottom: 12px;
          // }
        }
      }
      // .top-achi {
      //   display: flex;
      //   flex: row;
      //   // background: red;
      //   width: 100%;
      //   height: 100%;
      //   .img {
      //     width: 150px;
      //     height: 90px;
      //     border-radius: 6px;
      //     margin-right: 20px;
      //   }
      //   .word-container {
      //     // margin-top: 28px;
      //     // align-items: flex-start;
      //     // padding-left: 20px;
      //     // padding-right: 20px;
      //     // background: red;
      //     display: flex;
      //     flex-direction: column;
      //     // margin-top: 28px;
      //     // align-items: centery;
      //     flex-wrap: wrap;
      //     .word {
      //       font-size: 16px;
      //       font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      //       font-weight: 500;
      //       color: #101010;
      //       line-height: 24px;
      //       white-space: nowrap;
      //     }
      //     .infoGroup {
      //       margin-top: 16px;
      //       // padding: 20px;
      //       .item2 {
      //         margin-top: 12px;
      //       }
      //       .item {
      //         padding: 0px 20px 0px 0px;
      //         // border-radius: 8px;
      //         width: 100%;
      //         display: flex;
      //         flex-direction: row;
      //         justify-content: space-between;
      //         .itemLabel {
      //           width: 64px;
      //           white-space: nowrap;
      //           font-size: 12px;
      //           font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      //           font-weight: 400;
      //           color: #8896a7;
      //           line-height: 18px;
      //         }
      //         p {
      //           // background: #2885ff;
      //           font-size: 12px;
      //           font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      //           font-weight: 400;
      //           color: #101010;
      //           line-height: 18px;
      //         }
      //         .text {
      //           width: 208px;
      //           line-height: 20px;
      //         }
      //       }
      //     }

      //     .profession {
      //       margin-top: 16px;
      //     }
      //   }
      // }

      // .line {
      //   width: 100%;
      //   height: 1px;
      // }

      .miniword-container {
        padding-top: 13px;
        width: 332px;
        align-self: flex-start;
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #404040;
        .mini-word {
          // font-size: 16px;
          // font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          // font-weight: 400;
          // color: #666666;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
}
</style>
